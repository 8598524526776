<template>
  <section class="request-details">
    <Section>
      <section class="request-details__block">
        <Headline size="4">Match Information</Headline>
        <InputLayout label="Stage & Matchday">
          <span class="request-details__block-text">{{ getFromRequest('match.matchday.optionLabel') }}</span>
        </InputLayout>
        <InputLayout label="Match">
          <span class="request-details__block-text">{{ getFromRequest('match.name') }}</span>
        </InputLayout>
        <InputLayout label="Venue">
          <span class="request-details__block-text">{{ getFromRequest('match.venue.localTitle') }}</span>
        </InputLayout>
        <InputLayout label="Match Kick-off Time (UTC+8)">
          <span class="request-details__block-text">{{  getFromRequest('match.startDateTime') ? this.getMatchKickoffTime(getFromRequest('match.startDateTime')) : '' }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block request-details__block_tickets">
        <Headline size="4">Tickets Request</Headline>

        <InputLayout label="Type">
          <span class="request-details__block-text">{{ getFromRequest('type').charAt(0).toUpperCase() + getFromRequest('type').slice(1) }}</span>
        </InputLayout>
        <InputLayout label="Status">
          <span class="request-details__block-text">{{ getFromRequest('status').charAt(0).toUpperCase() + getFromRequest('status').slice(1).replace('_', ' ') }}</span>
        </InputLayout>

        <div
          class="ticket-details"
          v-for="(ticket, index) in getFromRequest('tickets')"
          :key="index"
        >
          <InputLayout label="Zone">
            <span class="request-details__block-text request-details__capitalize">{{ get(ticket, `zone.name`) || '--'}}</span>
          </InputLayout>
          <InputLayout label="Amount of requested tickets">
            <span class="request-details__block-text">{{ ticket.quantity }}</span>
          </InputLayout>
          <InputLayout label="Amount of approved tickets" v-if="getFromRequest('status') === 'approved'">
            <span class="request-details__block-text">{{ ticket.providedQuantity }}</span>
          </InputLayout>
          <InputLayout label="Price of tickets" v-if="getFromRequest('type') !== 'complimentary'">
            <span class="request-details__block-text">{{ ticket.price }} {{ticket.currency}}</span>
          </InputLayout>
          <InputLayout label="Sub-total" v-if="getFromRequest('type') !== 'complimentary' && getFromRequest('status') === 'approved'">
            <span class="request-details__block-text">{{ ticket.price * ticket.providedQuantity }} {{ticket.currency}}</span>
          </InputLayout>
        </div>

        <InputLayout label="TOTAL" v-if="getFromRequest('type') !== 'complimentary'">
          <span class="request-details__block-text">{{ totalPrice }} {{ticketsCurrency}}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Main Contact</Headline>
        <InputLayout label="First name">
          <span class="request-details__block-text">{{ getFromRequest('mainContactOverwrite.firstname', getFromRequest('mainContact.firstname')) }}</span>
        </InputLayout>
        <InputLayout label="Last name">
          <span class="request-details__block-text">{{ getFromRequest('mainContactOverwrite.lastname', getFromRequest('mainContact.lastname')) }}</span>
        </InputLayout>
        <InputLayout label="Email">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.email', getFromRequest('mainContact.contact.email')) }}</span>
        </InputLayout>
        <InputLayout label="Mobile">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.homePhone', getFromRequest('mainContact.contact.homePhone')) }}</span>
        </InputLayout>
        <InputLayout label="Address">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.street', getFromRequest('mainContact.contact.street')) }}</span>
        </InputLayout>
        <InputLayout label="City">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.city', getFromRequest('mainContact.contact.city')) }}</span>
        </InputLayout>
        <InputLayout label="Zip code">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.zip', getFromRequest('mainContact.contact.zip')) }}</span>
        </InputLayout>
        <InputLayout label="Country">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('mainContactOverwrite.country.name', getFromRequest('mainContact.contact.country.name')) }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Delivery Contact</Headline>
        <InputLayout label="First name">
          <span class="request-details__block-text">{{ getFromRequest('deliveryContactOverwrite.firstname', getFromRequest('deliveryContact.firstname')) }}</span>
        </InputLayout>
        <InputLayout label="Last name">
          <span class="request-details__block-text">{{ getFromRequest('deliveryContactOverwrite.lastname', getFromRequest('deliveryContact.lastname')) }}</span>
        </InputLayout>
        <InputLayout label="Email">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.email', getFromRequest('deliveryContact.contact.email')) }}</span>
        </InputLayout>
        <InputLayout label="Mobile">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.homePhone', getFromRequest('deliveryContact.contact.homePhone')) }}</span>
        </InputLayout>
        <InputLayout label="Address">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.street', getFromRequest('deliveryContact.contact.street')) }}</span>
        </InputLayout>
        <InputLayout label="City">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.city', getFromRequest('deliveryContact.contact.city')) }}</span>
        </InputLayout>
        <InputLayout label="Zip code">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.zip', getFromRequest('deliveryContact.contact.zip')) }}</span>
        </InputLayout>
        <InputLayout label="Country">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('deliveryContactOverwrite.country.name', getFromRequest('deliveryContact.contact.country.name')) }}</span>
        </InputLayout>
      </section>
    </Section>
    <div class="btn-wrapper">
      <Button
          type="primary"
          @click="navigateToOverview()"
      >
        Back
      </Button>
      <Button
          type="danger"
          class="delete-button"
          @click="deleteTicketRequest()"
          v-if="getFromRequest('status') === 'requested'"
      >
        Delete
      </Button>
    </div>
    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import Headline from '@/components/Headline/Headline.component';
import InputLayout from '@/components/Inputs/components/InputLayout.component';
import Section from '@/components/Section/Section.component';
import Button from "@/components/Button/Button.component";

import moment from "moment";

import get from "lodash.get";
import RouterNames from "@/router/route.names";
import {DtoApiDeleteRequestTicketRequest} from "../../../classes/dto/api/ticket/dto.api.delete-request-ticket.request";

export default {
  components: {
    Headline,
    InputLayout,
    Section,
    Button
  },
  data: () => ({
    currency: {
      '': '$',
      'USD': '$',
      'EUR': '€',
      'EU': '€',
    }
  }),
  computed: {
    ticketRequestDetails() {
      return get(this, '$store.state.ticketRequestList.ticketRequestDetails')
    },
    totalPrice() {
      let tickets = this.getFromRequest('tickets')
      const price = tickets && tickets !== '--' ? tickets.reduce((accumulator, ticket) => {
        let subtotal = +ticket.price * +ticket.quantity
        return accumulator + subtotal
      }, 0) : 0

      return price.toFixed(2)
    },
    ticketsCurrency() {
      let tickets = this.getFromRequest('tickets');
      let firstTicket = tickets[0];
      return firstTicket ? firstTicket.currency : 'USD';
    },
  },
  methods: {
    async deleteTicketRequest() {
      this.$confirm(
          {
            message: `Are you sure you want to delete this ticket request?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteRequestTicketRequest().$deleteTicketRequest([this.getFromRequest('ticketRequestId')], this.$store.state.user.account.id).then(() => {
                  this.navigateToOverview()
                })
              }
            }
          })
    },
    currencyToSymbol(key) {
      return key;
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestList;
      this.$router.push({name});
    },
    get,
    moment,
    getFromRequest(path, fallback) {
      const data = get(this, `ticketRequestDetails.${path}`, ) || fallback;

      return data ? data : '--'
    },
    getSingleTicketRequest(data) { this.$store.dispatch('ticketRequestList/getSingleTicketRequest', data) },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToTickets'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    getMatchKickoffTime(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY HH:mm')
    },
  },
  created() {
    this.checkPermissions()
    const ticketRequestId = this.$route.params.request
    this.getSingleTicketRequest(ticketRequestId)
  }
}
</script>

<style lang="scss">
@import "../../../styles/mixins/ellipsis.mixin";

.request-details {

  .delete-button {
    margin-left: 20px;
  }
  &__block {
    margin-bottom: 50px;
  }
  &__section,
  &__comments {
    margin-top: 60px;
  }

  &__block_tickets {
    max-width: 430px;
  }

  .ticket-details {
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c0c0c0;
  }

  .ticket-details:first-of-type {
    margin-top: 25px;
  }

  .headline-component {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }

  .InputLayout {
    margin-bottom: 10px;
  }

  .InputLabel__label {
    @include ellipsis;
    $width: 250px;
    overflow: visible;
    min-width: $width;
    max-width: $width;
    width: $width;
    font-weight: 400;
    font-size: 14px;
  }

  &__passport-photo {
    max-width: 220px;
  }

  &__capitalize {
    text-transform: capitalize;
  }
}
</style>
